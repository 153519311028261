<template>
  <div>
    <b-row>
      <b-col>
        <div id="component-breadcrumbs">
          <b-breadcrumb
            class="breadcrumb-slash"
            :items="breadcrumbItems"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>Case Log History</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + caseObject.CaseID + '/detail'"
        >
          <h3>Case No: {{ caseObject.FileNumber }}</h3>
        </router-link>
      </b-col>
      <b-col>
        <h3>State: {{ caseObject.CaseStateCode }}</h3>
      </b-col>
      <b-col />
    </b-row>
    <b-row>
      <b-col>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Date -->
            <span
              v-if="props.column.field === 'LogDateTime'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.LogDateTime | dateFormat }}</span>
            </span>

            <!-- Column User -->
            <span v-else-if="props.column.field === 'UserFullName'">
              <router-link :to="'/clients/view/' + props.row.UserID">
                {{ props.formattedRow[props.column.field] }}
              </router-link>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBreadcrumb, BCol, BPagination, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    VueGoodTable,
  },
  filters: {
    dateFormat(val) {
      if (val) return val.substr(0, 10);
      return val
    }
  },
  data() {
    return {
      CaseID: "",
      caseObject: {},
      rows: [],
      columns: [
        {
          label: "Date",
          field: "LogDateTime",
        },
        {
          label: "User",
          field: "UserFullName",
        },
        {
          label: "Action",
          field: "LogMsgType",
        },
        {
          label: "Notes",
          field: "Text",
        },
      ],
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: 'Case',
        },
        {
          text: 'case history',
        },
      ]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  async created() {
    try {
      this.CaseID = this.$route.params.caseId;
      apiService
          .get("case/" + this.CaseID + "/detail")
          .then((res) => {
            this.caseObject = res.data;
          })
      apiService
          .get("logs/Case/" + this.CaseID)
          .then(res => {
            this.rows = res.data;
          })
    } catch (err) {
      this.error = err;
    }
  }
}
</script>

<style scoped>

</style>